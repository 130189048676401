export enum Panel {
  Alternating = "alternating",
  Solid = "solid"
}

export enum Valance {
  Canopy = "none",
  Standard = "standard",
  Scallop = "scallop",
  Trojan = "trojan",
  Whale = "whale"
}

export enum Rib {
  Pockets = "pocket",
  Grommets = "grommet"
}

export enum BottomPole {
  Standard = "standard",
  Bar = "bar"
}

export enum Finial {
  Ball = "ball",
  Vertex = "vertex"
}

export enum FinialFinishType {
  TPU = "tpu",
  C = "chrome",
  SS = "stainless steel"
}

export enum Spigot {
  Standard = "standard",
  StainlessSteel = "stainless steel"
}

export enum Preset {
  S1 = "S1",
  S1DV = "S1DV",
  D1 = "D1",
  D2 = "D2",
  D3 = "D3",
  D4 = "D4",
  D5 = "D5"
}

export enum Stem {
  Stem8ST = "8ST",
  Stem18ST = "18ST",
  Stem18ST2 = "18ST2"
}
